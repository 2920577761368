import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIEndPoint } from '../core/constants/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class StateLicenseService {
  //api_end_point:string="https://staging.mocingbird.com/api/v1/courses"//staging
  api_end_point: string = APIEndPoint.ENDPOINT_URL + '/api/v1/state_licenses' //local
  constructor(private http: HttpClient) {}

  getStateLicenses(pageNo:any, sortBy:any, direction:any) {
    return this.http.get(this.api_end_point + '?page=' + pageNo + '&sort_by=' + sortBy + '&dir=' + direction);
  }
  deleteStateLicense(id:any) {
    return this.http.delete(this.api_end_point + '/' + id)
  }
  getStateLicense(id:any) {
    return this.http.get(this.api_end_point + '/' + id)
  }

  saveStateLicense(
    state:any,
    license_number:any,
    date_of_expiry:any,
    certificate_file_remote_url:any,
    user_file_id:any,
    prescription_privileges:any
  ) {
    return this.http.post(this.api_end_point, {
      state_license: {
        state: state,
        license_number: license_number,
        date_of_expiry: date_of_expiry,
        prescription_privileges
        // certificate_file_remote_url: certificate_file_remote_url,
      },
      user_file_id: user_file_id
    })
  }

  saveStateLicenseWithParams(params:any) {
    return this.http.post(this.api_end_point, params)
  }
  updateStateLicenseWithParams(id:any, params:any) {
    return this.http.put(this.api_end_point + '/' + id, params)
  }
  updateStateLicense(
    id:any,
    state:any,
    license_number:any,
    date_of_expiry:any,
    prescription_privileges:any
  ) {
    return this.http.put(this.api_end_point + '/' + id, {
      state_license: {
        state: state,
        license_number: license_number,
        date_of_expiry: date_of_expiry,
        prescription_privileges: prescription_privileges
      }
    })
  }

  parseDataForCertificate(remote_url:any) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/parsers', {
      remote_url: remote_url
    })
  }

  getStateLicenseNumbers(state:any) {
    return this.http.get(
      APIEndPoint.ENDPOINT_URL +
        '/api/v1/state_license_suggestions?state=' +
        state
    )
  }
  getStates() {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/states')
  }

  getNPStates(userType:any) {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/states?user_type=' + userType)
  }

  saveControlledSubstanceRegistration(
    state:any,
    credential_number:any,
    effective_date:any,
    expiration_date:any,
    scheduleList:any,
    user_file_id:any
  ) {
    let payload:any = {
      controlled_substance_registration: {
        state,
        date_of_expiry: expiration_date,
        number: credential_number,
        flags: {
          schedule: scheduleList,
          initial_date: effective_date,
        },
      },
      user_file_id: user_file_id,
    };
    for (let i = 0; i < scheduleList?.length; i++) {
      let scheduleKey = 'schedule_'+(i + 1);
      if (scheduleList[i].value) {
        payload.controlled_substance_registration.flags[scheduleKey] = true;
      }else {
        payload.controlled_substance_registration.flags[scheduleKey] = false;
      }
    }
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + "/api/v1/controlled_substance_registrations",
      payload
    );
  }

  updateCycle(id:any, license_type:any, data:any) {
    return this.http.put(
      APIEndPoint.ENDPOINT_URL +
        '/api/v3/taskables/' +
        license_type +
        '/' +
        id +
        '/update_cycle',
      data
    );
  }

  stateLicenseActivateReq(id:number, payload:any) {
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + "/api/v1/state_licenses/" + id + '/request_activation',
      payload
    );
  }
  stateLicenseDeactivateReq(id:number, payload:any) {
    return this.http.put(
      APIEndPoint.ENDPOINT_URL + "/api/v1/state_licenses/" + id + '/deactivate_state_license',
      payload
    );
  }

  getStateRequiredCourses(){
    return this.http.get(APIEndPoint.ENDPOINT_URL + "/api/v1/state_required_courses");
  }

  getClinicianList(){
    return this.http.get(APIEndPoint.ENDPOINT_URL + "/api/v1/state_required_courses/clinicians");
  }

  getContentProviderList(){
    return this.http.get(APIEndPoint.ENDPOINT_URL + "/api/v1/state_required_courses/content_providers");
  }
 
}
